/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'round-robin': {
    width: 32,
    height: 33,
    viewBox: '0 0 32 33',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M1.572 5.17v2.337h11.714V5.17H1.572zm0 5.72V8.506h11.714v2.382H1.572zm0 1v1.818h11.714V11.89H1.572zm-1-9.712a1 1 0 011-1h11.714a1 1 0 011 1v11.53a1 1 0 01-1 1H1.572a1 1 0 01-1-1V2.178zM18.714 5.17v2.337H30.43V5.17H18.714zm0 5.72V8.506H30.43v2.382H18.714zm0 1v2.002H30.43v-2.003H18.714zm-1-9.712a1 1 0 011-1H30.43a1 1 0 011 1v11.714a1 1 0 01-1 1H18.714a1 1 0 01-1-1V2.178zM1.572 22.037v2.383h11.714v-2.383H1.572zm0 5.765V25.42h11.714v2.382H1.572zm0 1v2.003h11.714v-2.003H1.572zm-1-9.711a1 1 0 011-1h11.714a1 1 0 011 1v11.714a1 1 0 01-1 1H1.572a1 1 0 01-1-1V19.091zM18.714 22.083v2.337H30.43v-2.337H18.714zm0 5.72V25.42H30.43v2.382H18.714zm0 1v2.002H30.43v-2.003H18.714zm-1-9.712a1 1 0 011-1H30.43a1 1 0 011 1v11.714a1 1 0 01-1 1H18.714a1 1 0 01-1-1V19.091z" _fill="#fff"/>'
  }
})
